import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const PageHelmet = ({ message }) => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <link
        rel="shortcut icon"
        type="image/png"
        href={process.env.PUBLIC_URL + '/okomoFavicon.png'}
      />
      <title>{`${t(message)} - Okomo`}</title>
    </Helmet>
  );
};

export default PageHelmet;
