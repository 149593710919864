import { styled } from '@mui/material';

export const ChipDiv = styled('div')(
  ({ theme, sidebarOpen }) => `
  .MuiChip-root {
    background: #5E90cc;
    position: absolute;
    margin-top: ${sidebarOpen ? '-0.2' : '1'}rem;
    margin-left: ${sidebarOpen ? '8.25' : '1.3'}rem;
    height: 14px;
    width: 37.5px;
    color: ${theme.palette.primary.contrastText};
    font-size: 10px;
    font-weight: bold;
    cursor : pointer;
    span {
      padding: 2px;
    }
  }
`,
);
