export const validationMessage = (errorCode) => {
  switch (errorCode) {
    case '00001':
      return '에러코드 처리가 필요합니다.';

    case '00002':
      return '에러코드 처리가 필요합니다.';

    case '00003':
      return '에러코드 처리가 필요합니다.';

    case '00004':
      return '그룹 내에는 관리자 권한을 소유한 계정이 적어도 하나 이상 필요합니다.';

    case '00006':
      return '이메일 인증 요청 횟수가 제한되었습니다.\n1분 후 다시 시도해주세요.';

    case '00007':
      return '이메일 인증 요청 횟수가 제한되었습니다.\n1분 후 다시 시도해주세요.';

    case '00008':
      return '이메일 인증 요청 횟수가 제한되었습니다.\n1분 후 다시 시도해주세요.';

    case '00009':
      return '그룹 내의 유일한 관리자 계정은 탈퇴할 수 없습니다.';

    case '00010':
      return '이미 제어 요청이 진행 중입니다.';

    case '00011':
      return '장비에 연결할 수 없습니다. EDMU 연결을 확인해주세요.';

    case '00012':
      return '장비의 통신이 원활하지 않습니다';

    case '10001':
      return '에러코드 처리가 필요합니다.';

    case '10002':
      return '에러코드 처리가 필요합니다.';

    case '10003':
      return '에러코드 처리가 필요합니다.';

    case '10004':
      return '에러코드 처리가 필요합니다.';

    case '10005':
      return '에러코드 처리가 필요합니다.';

    case '10006':
      return '에러코드 처리가 필요합니다.';

    case '10007':
      return '에러코드 처리가 필요합니다.';

    case '10008':
      return '에러코드 처리가 필요합니다.';

    case '10009':
      return '에러코드 처리가 필요합니다.';

    case '10010':
      return '에러코드 처리가 필요합니다.';

    case '10011':
      return '에러코드 처리가 필요합니다.';

    case '10012':
      return '에러코드 처리가 필요합니다.';

    case '10013':
      return '에러코드 처리가 필요합니다.';

    case '10014':
      return '에러코드 처리가 필요합니다.';

    case '10015':
      return '토큰은 필수 항목입니다';

    case '10016':
      return '에러코드 처리가 필요합니다.';

    case '10017':
      return '에러코드 처리가 필요합니다.';

    case '10018':
      return '에러코드 처리가 필요합니다.';

    case '10019':
      return '에러코드 처리가 필요합니다.';

    case '10020':
      return '사용자 ID는 필수 항목입니다';

    case '10021':
      return '에러코드 처리가 필요합니다.';

    case '10022':
      return '그룹 이름은 필수 항목입니다';

    case '10023':
      return '에러코드 처리가 필요합니다.';

    case '10024':
      return '에러코드 처리가 필요합니다.';

    case '10025':
      return '에러코드 처리가 필요합니다.';

    case '10026':
      return '에러코드 처리가 필요합니다.';

    case '10027':
      return '에러코드 처리가 필요합니다.';

    case '10028':
      return '에러코드 처리가 필요합니다.';

    case '10029':
      return '에러코드 처리가 필요합니다.';

    case '10030':
      return '에러코드 처리가 필요합니다.';

    case '10031':
      return '에러코드 처리가 필요합니다.';

    case '10032':
      return '에러코드 처리가 필요합니다.';

    case '10033':
      return '에러코드 처리가 필요합니다.';

    case '10034':
      return '에러코드 처리가 필요합니다.';

    case '10035':
      return '에러코드 처리가 필요합니다.';

    case '10036':
      return '에러코드 처리가 필요합니다.';

    case '10037':
      return '에러코드 처리가 필요합니다.';

    case '10038':
      return '에러코드 처리가 필요합니다.';

    case '10039':
      return '에러코드 처리가 필요합니다.';

    case '10040':
      return '장비 시리얼은 필수 항목입니다.';

    case '10041':
      return '장비 위치는 필수 항목입니다.';

    case '10042':
      return '장비 이름은 필수 항목입니다.';

    case '10043':
      return '에러코드 처리가 필요합니다.';

    case '10044':
      return '에러코드 처리가 필요합니다.';

    case '10045':
      return '주소 별명은 필수 항목입니다.';

    case '10046':
      return `장비 위치는 필수 항목입니다.`;

    case '10047':
      return '에러코드 처리가 필요합니다.';

    case '10048':
      return '에러코드 처리가 필요합니다.';

    case '10049':
      return '에러코드 처리가 필요합니다.';

    case '10050':
      return '에러코드 처리가 필요합니다.';

    case '10051':
      return '에러코드 처리가 필요합니다.';

    case '10052':
      return '에러코드 처리가 필요합니다.';

    case '10053':
      return '에러코드 처리가 필요합니다.';

    case '10054':
      return '에러코드 처리가 필요합니다.';

    case '10055':
      return '에러코드 처리가 필요합니다.';

    case '10056':
      return '에러코드 처리가 필요합니다.';

    case '10057':
      return '에러코드 처리가 필요합니다.';

    case '10058':
      return '에러코드 처리가 필요합니다.';

    case '10059':
      return '에러코드 처리가 필요합니다.';

    case '10060':
      return '에러코드 처리가 필요합니다.';

    case '10061':
      return '에러코드 처리가 필요합니다.';

    case '10062':
      return '에러코드 처리가 필요합니다.';

    case '10063':
      return '에러코드 처리가 필요합니다.';

    case '10064':
      return '에러코드 처리가 필요합니다.';

    case '10065':
      return '장비 현재값이 입력되지 않았습니다.';

    case '10066':
      return '장비 제어값이 입력되지 않았습니다.';

    case '10067':
      return '제어 비밀번호[CU Key]를 입력해주세요.';

    case '10068':
      return '에러코드 처리가 필요합니다.';

    case '10069':
      return '에러코드 처리가 필요합니다.';

    case '10070':
      return '에러코드 처리가 필요합니다.';

    case '10071':
      return '에러코드 처리가 필요합니다.';

    case '10072':
      return '제어 비밀번호[CU Key]는 필수 항목입니다';

    case '10073':
      return '에러코드 처리가 필요합니다.';

    case '10074':
      return '에러코드 처리가 필요합니다.';

    case '10075':
      return '제어 비밀번호[CU Key]는 필수 항목입니다';

    case '10076':
      return '에러코드 처리가 필요합니다.';

    case '10077':
      return '에러코드 처리가 필요합니다.';

    case '10078':
      return '에러코드 처리가 필요합니다.';

    case '10079':
      return '에러코드 처리가 필요합니다.';

    case '10080':
      return '에러코드 처리가 필요합니다.';

    case '10081':
      return '에러코드 처리가 필요합니다.';

    case '10082':
      return '비밀번호는 필수 항목입니다';

    case '10083':
      return '에러코드 처리가 필요합니다.';

    case '10084':
      return '에러코드 처리가 필요합니다.';

    case '10085':
      return '에러코드 처리가 필요합니다.';

    case '10086':
      return '에러코드 처리가 필요합니다.';

    case '10087':
      return '에러코드 처리가 필요합니다.';

    case '10088':
      return '에러코드 처리가 필요합니다.';

    case '10089':
      return '에러코드 처리가 필요합니다.';

    case '10090':
      return '에러코드 처리가 필요합니다.';

    case '10094':
      return '장비 ID는 필수 항목입니다';

    case '10109':
      return '장비 ID는 필수 항목입니다';

    case '10112':
      return '장비 시리얼은 필수 항목입니다';

    case '10114':
      return '제어 비밀번호[CU Key]를 입력해주세요.';

    case '10137':
      return '헤더는 필수 항목입니다';

    case '10149':
      return '비밀번호를 입력해 주세요';

    case '10186':
      return '주소 별명은 필수 항목입니다.';

    case '10227':
      return '상세 데이터 정보 이름은 필수 항목입니다';

    case '20000':
      return '허가되지 않은 데이터 항목이 포함되있습니다.\n 관리자에게 문의해주세요';

    case '20001':
      return '장비 타입이 유효하지 않습니다.';

    case '20002':
      return '에러코드 처리가 필요합니다.';

    case '20003':
      return '에러코드 처리가 필요합니다.';

    case '20004':
      return '에러코드 처리가 필요합니다.';

    case '20005':
      return '에러코드 처리가 필요합니다.';

    case '20006':
      return '유효하지 않은 접근입니다.\n 다시 시도해 주세요.';

    case '20007':
      return '에러코드 처리가 필요합니다.';

    case '20008':
      return '에러코드 처리가 필요합니다.';

    case '20009':
      return '현재 비밀번호와 다른 비밀번호를 입력해 주세요.';

    case '20010':
      return '입력하신 정보로 등록된 이메일을 찾을 수 없습니다. 다시 입력해 주세요.';

    case '20011':
      return '에러코드 처리가 필요합니다.';

    case '20012':
      return '에러코드 처리가 필요합니다.';

    case '20013':
      return '에러코드 처리가 필요합니다.';

    case '20014':
      return '에러코드 처리가 필요합니다.';

    case '20015':
      return '에러코드 처리가 필요합니다.';

    case '20016':
      return '에러코드 처리가 필요합니다.';

    case '20017':
      return '가입되지 않은 회원입니다. 정보를 다시 확인해 주세요.';

    case '20018':
      return '회원 가입 시 입력한 정보와 일치하는 이메일 주소를 입력해야합니다.\n 다시 수행해 주세요.';

    case '20019':
      return '가입되지 않은 회원입니다. 정보를 다시 확인해 주세요.';

    case '20020':
      return '잘못된 비밀번호입니다. 다시 시도해 주세요.';

    case '20021':
      return '에러코드 처리가 필요합니다.';

    case '20022':
      return '잘못된 비밀번호입니다. 다시 시도해 주세요.';

    case '20023':
      return '에러코드 처리가 필요합니다.';

    case '20024':
      return '잘못된 제어 비밀번호[CU Key]입니다. 다시 시도해 주세요.';

    case '20025':
      return '현재 비밀번호와 다른 비밀번호를 입력해 주세요.';

    case '20026':
      return '에러코드 처리가 필요합니다.';

    case '20027':
      return '유효하지 않은 그룹코드입니다.';

    case '20028':
      return '에러코드 처리가 필요합니다.';

    case '20029':
      return '올바르지 않은 태그가 포함되어있습니다.\n 태그는 문자, 숫자, _(언더바), 띄어쓰기만 가능합니다.';

    case '20030':
      return '해당 장비 위치가 존재하지 않습니다.';

    case '20031':
      return '에러코드 처리가 필요합니다.';

    case '20032':
      return '에러코드 처리가 필요합니다.';

    case '20033':
      return '에러코드 처리가 필요합니다.';

    case '20034':
      return '에러코드 처리가 필요합니다.';

    case '20035':
      return '에러코드 처리가 필요합니다.';

    case '20036':
      return '에러코드 처리가 필요합니다.';

    case '20037':
      return '에러코드 처리가 필요합니다.';

    case '20038':
      return '에러코드 처리가 필요합니다.';

    case '20039':
      return '에러코드 처리가 필요합니다.';

    case '20040':
      return '에러코드 처리가 필요합니다.';

    case '20041':
      return '에러코드 처리가 필요합니다.';

    case '20042':
      return '에러코드 처리가 필요합니다.';

    case '20043':
      return '에러코드 처리가 필요합니다.';

    case '20044':
      return '에러코드 처리가 필요합니다.';

    case '20045':
      return '에러코드 처리가 필요합니다.';

    case '20046':
      return '유효하지 않은 그룹코드입니다.';

    case '20047':
      return '에러코드 처리가 필요합니다.';

    case '20048':
      return '에러코드 처리가 필요합니다.';

    case '20049':
      return '에러코드 처리가 필요합니다.';

    case '20050':
      return '에러코드 처리가 필요합니다.';

    case '20051':
      return '에러코드 처리가 필요합니다.';

    case '20052':
      return '에러코드 처리가 필요합니다.';

    case '20053':
      return '에러코드 처리가 필요합니다.';

    case '20054':
      return '에러코드 처리가 필요합니다.';

    case '20055':
      return '에러코드 처리가 필요합니다.';

    case '20056':
      return '에러코드 처리가 필요합니다.';

    case '20057':
      return '에러코드 처리가 필요합니다.';

    case '20058':
      return '에러코드 처리가 필요합니다.';

    case '20059':
      return '유효하지 않은 언어입니다';

    case '20060':
      return '유효하지 않은 언어입니다';

    case '20061':
      return '에러코드 처리가 필요합니다.';

    case '20070':
      return '현재 비밀번호와 다른 비밀번호를 입력해 주세요';

    case '20071':
      return '유효하지 않은 접근입니다.\n 다시 시도해 주세요.';

    case '20072':
      return '인증 정보가 잘못되었거나 만료되어 인증처리에 실패하였습니다. \n다시 시도해 주세요.';

    case '20073':
      return '인증 정보가 잘못되었거나 만료되어 인증처리에 실패하였습니다. \n다시 시도해 주세요.';

    case '20074':
      return '잘못된 사용자 정보입니다. \n다시 시도해 주세요';

    case '20077':
      return '유효하지 않은 언어입니다';

    case '20081':
      return '가입되지 않은 회원입니다. 정보를 다시 확인해 주세요.';

    case '20082':
      return '이미 처리된 계정입니다.';

    case '20083':
      return '유효하지 않은 접근입니다.\n 다시 시도해 주세요.';

    case '20085':
      return '유효하지 않은 국가정보 입니다.';

    case '20087':
      return '즐겨찾기에 등록되어 있지 않은 장비입니다.';

    case '20089':
      return '유효하지 않은 제어 키 입니다.';

    case '20096':
      return '유효하지 않은 인증코드입니다.';

    case '20099':
      return '존재하지 않는 인증코드입니다.';

    case '20100':
      return '만료된 인증코드입니다.';

    case '20102':
      return '6자리의 인증코드를 입력해주세요.';

    case '20108':
      return '잘못된 비밀번호입니다. 다시 시도해 주세요.';

    case '20132':
      return '제품 ID가 유효하지 않습니다.';

    case '30001':
      return 'EDMU 연결이 필요합니다';

    case '30002':
      return '에러코드 처리가 필요합니다.';

    case '30003':
      return '등록되지 않은 장비 ID입니다.';

    case '30004':
      return '관리자 외에는 권한이 없습니다';

    case '30005':
      return '에러코드 처리가 필요합니다.';

    case '30006':
      return '이미 가입된 그룹가 존재합니다.';

    case '30007':
      return '이메일이 인증되지 않았습니다. 발송된 인증 메일을 확인해 주세요.';

    case '30008':
      return '에러코드 처리가 필요합니다.';

    case '30009':
      return '에러코드 처리가 필요합니다.';

    case '30010':
      return '잘못된 장비 시리얼입니다. \n해당 장비 시리얼과 라우터 시리얼을 확인해주세요.';

    case '30011':
      return '에러코드 처리가 필요합니다.';

    case '30012':
      return '자신의 권한은 수정할 수 없습니다.';

    case '30014':
      return '이메일이 인증되지 않았습니다. 발송된 인증 메일을 확인해 주세요.';

    case '30017':
      return '가입되지 않은 회원입니다. 정보를 다시 확인해 주세요.';

    case '30025':
      return '삭제됐거나 존재하지 않는 수리 이력입니다.';

    case '40001':
      return '사용자 ID는 숫자로 입력해주세요.';

    case '40002':
      return '사용자 ID는 숫자로 입력해주세요.';

    case '40003':
      return '장비 ID는 숫자로 입력해주세요.';

    case '40004':
      return '장비 위치 ID는 숫자로 입력해주세요';

    case '40005':
      return '에러코드 처리가 필요합니다.';

    case '40006':
      return '에러코드 처리가 필요합니다.';

    case '40007':
      return '에러코드 처리가 필요합니다.';

    case '40008':
      return '에러코드 처리가 필요합니다.';

    case '40009':
      return '에러코드 처리가 필요합니다.';

    case '40010':
      return '에러코드 처리가 필요합니다.';

    case '40069':
      return '데이터 개수는 최대 10개까지 가능합니다';

    case '40074':
      return '데이터 개수는 최대 10개까지 가능합니다';

    case '40147':
      return '라우터 시리얼은 숫자로 입력해주세요.';

    case '50001':
      return '에러코드 처리가 필요합니다.';

    case '50002':
      return '에러코드 처리가 필요합니다.';

    case '50003':
      return '에러코드 처리가 필요합니다.';

    case '50004':
      return '에러코드 처리가 필요합니다.';

    case '50005':
      return '이미 사용 중인 이메일입니다.';

    case '50006':
      return '이미 존재하는 장비 시리얼 입니다.';

    case '50007':
      return '이미 존재하는 주소 별명 입니다.';

    case '50008':
      return '이미 사용 중인 휴대 전화번호입니다.';

    case '50009':
      return '이미 사용 중인 장비 이름입니다';

    case '50011':
      return '이미 존재하는 그룹입니다.\n가입을 희망한다면 관리자에게 링크를 요청해주세요';

    case '50012':
      return '이미 즐겨찾기에 등록된 장비입니다.';

    case '50014':
      return '이미 사용중인 시리얼 번호 입니다';

    case '50015':
      return '이미 사용중인 장비 이름 입니다';

    case '50019':
      return '이미 존재하는 업체명 입니다.';

    case '50021':
      return '이미 존재하는 제품명 입니다.';

    default:
      return '에러코드 처리가 필요합니다.';
  }
};
