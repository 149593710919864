import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import { onSnapshot } from 'firebase/firestore';
import {
  getBigQueryApiResult,
  getFirestoreQueryResult,
  getDeviceConnection,
} from 'src/api/device';
// test
import {
  GetDataConfig,
  ParameterInfo,
  FirebaseConfig,
} from 'src/assets/parameter/Summary';
import { getDeviceSummaryData } from 'src/api/deviceSummary';

class DeviceSummaryStore {
  // dummy, 수정필요, ui 확인용
  accountStore;

  alertStore;

  deviceStore;

  BIGQUERY = 1;

  FIRESTORE = 0;

  firestoreSnapshotArr = [];

  deviceList = [];

  dashboardFilteredData = [];

  dashboardFilter = {
    type: '',
    location: '',
    tag: [],
  };

  deviceConnStatus = [];

  initializeSummary = async () => {
    this.deviceList = [];

    this.deviceFilter = {
      type: '',
      location: '',
      tag: [],
    };
  };

  constructor({ accountStore, alertStore, deviceStore }) {
    makeAutoObservable(this);
    this.accountStore = accountStore;
    this.alertStore = alertStore;
    this.deviceStore = deviceStore;
  }

  setFilterType = (type) => {
    runInAction(() => {
      this.dashboardFilter.type = type;
    });
  };

  setFilterLocation = (locationId) => {
    runInAction(() => {
      this.dashboardFilter.location = String(locationId);
    });
  };

  setFilterTag = (tagArr) => {
    runInAction(() => {
      this.dashboardFilter.tag = tagArr;
    });
  };

  updateDeviceList = async () => {
    const extraInfo = {
      detailData: {
        bigquery: {
          data: null,
          status: false,
          lastCommTime: '통신상태 확인 중..',
        },
        firestore: {
          data: null,
          status: false,
          lastCommTime: '통신상태 확인 중..',
        },
      },
    };

    const newDeviceList = this.dashboardFilteredData.map((router) => {
      const newDevices = router.devices.map((device) => {
        const parameterInfo = {
          getDataConfig: GetDataConfig[device.deviceData.type],
          parameter: ParameterInfo[device.deviceData.type],
          firebaseConfig: FirebaseConfig[device.deviceData.type],
        };

        device.deviceData = {
          ...device.deviceData,
          ...parameterInfo,
        };
        return (device = {
          ...device,
          ...extraInfo,
        });
      });

      return { ...router, devices: newDevices };
    });

    runInAction(() => {
      this.dashboardFilteredData = newDeviceList;
    });
  };

  getDeviceSummaryData = async () => {
    // 라우터가 아닌 모든 장비타입의 id를 return
    function getNonRouterIds(data) {
      const ids = data
        .filter((item) => item.name !== 'router')
        .map((item) => item.id.toString());

      return ids;
    }

    try {
      const res = await getDeviceSummaryData(
        getNonRouterIds(this.deviceStore.deviceTypes.detail)?.join(','),
        this.dashboardFilter.location,
        this.dashboardFilter.tag.map((t) => t.id).toString(),
      );
      runInAction(() => {
        this.dashboardFilteredData = res;
      });
      return res;
    } catch (error) {
      // console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  setBigqueryData = (routerIdx, deviceIdx, dataCategory, getInfo, newValue) => {
    if (
      !this.dashboardFilteredData[routerIdx]?.devices[deviceIdx]?.detailData
        ?.bigquery?.data
    ) {
      runInAction(() => {
        if (
          !this.dashboardFilteredData[routerIdx]?.devices[deviceIdx]?.detailData
        ) {
          this.dashboardFilteredData[routerIdx].devices[deviceIdx].detailData =
            { bigquery: {} };
        }

        this.dashboardFilteredData[routerIdx].devices[
          deviceIdx
        ].detailData.bigquery.data = {};
      });
    }

    if (
      !Object.keys(
        this.dashboardFilteredData[routerIdx].devices[deviceIdx].detailData
          .bigquery?.data,
      ).includes(dataCategory)
    )
      runInAction(() => {
        this.dashboardFilteredData[routerIdx].devices[
          deviceIdx
        ].detailData.bigquery.data[dataCategory] = {};
      });

    if (
      this.dashboardFilteredData[routerIdx].devices[deviceIdx].detailData
        .bigquery?.data?.[dataCategory]?.[getInfo?.period || 'noPeriod']
    ) {
      const prevData =
        this.dashboardFilteredData[routerIdx].devices[deviceIdx].detailData
          .bigquery?.data[dataCategory][getInfo?.period || 'noPeriod'];

      Object.keys(prevData).some((parameterKey) => {
        if (getInfo.includeNull) {
          prevData[parameterKey]?.some((measData, dataIndex) => {
            if (measData[1] !== 0 && !measData[1]) {
              const emptyDataIndex = dataIndex;
              if (
                measData[1] !== newValue[parameterKey]?.[emptyDataIndex]?.[1]
              ) {
                this.dashboardFilteredData[routerIdx].devices[
                  deviceIdx
                ].detailData.bigquery.data[dataCategory][
                  getInfo.period || 'noPeriod'
                ][parameterKey]?.splice(
                  emptyDataIndex,
                  1,
                  newValue[parameterKey]?.[emptyDataIndex],
                );
                return true;
              }
              return false;
            }
            return false;
          });
          return false;
        }

        const lastDataIndex = prevData[parameterKey].length - 1;

        // case 1. 같은 타임스탬프 + 평균값 연산 결과만 다를때
        if (
          prevData[parameterKey]?.[lastDataIndex]?.[1] !==
            newValue[parameterKey]?.[lastDataIndex]?.[1] &&
          prevData[parameterKey]?.[lastDataIndex]?.[0] ===
            newValue[parameterKey]?.[lastDataIndex]?.[0]
        ) {
          this.dashboardFilteredData[routerIdx].devices[
            deviceIdx
          ].detailData.bigquery.data[dataCategory][
            getInfo.period || 'noPeriod'
          ][parameterKey][lastDataIndex][1] =
            newValue[parameterKey]?.[lastDataIndex]?.[1];
          return true;
        }

        // case 2. 데이터 길이 자체가 다를 때 (초기)
        if (prevData[parameterKey].length !== newValue[parameterKey].length) {
          this.dashboardFilteredData[routerIdx].devices[
            deviceIdx
          ].detailData.bigquery.data[dataCategory][
            getInfo.period || 'noPeriod'
          ][parameterKey] = newValue[parameterKey];
          return true;
        }

        // case 3. 새로운 데이터가 들어왔을 때 (데이터 길이가 다 찬 이후)
        if (
          prevData[parameterKey]?.[lastDataIndex]?.[0] !==
          newValue[parameterKey]?.[lastDataIndex]?.[0]
        ) {
          // runInAction(() => {
          this.dashboardFilteredData[routerIdx].devices[
            deviceIdx
          ].detailData.bigquery.data[dataCategory][
            getInfo.period || 'noPeriod'
          ][parameterKey].shift();
          this.dashboardFilteredData[routerIdx].devices[
            deviceIdx
          ].detailData.bigquery.data[dataCategory][
            getInfo.period || 'noPeriod'
          ][parameterKey]?.push(newValue?.[parameterKey]?.[lastDataIndex]);
          return false;
          // });
        }
        return true;
      });
    } else {
      runInAction(() => {
        this.dashboardFilteredData[routerIdx].devices[
          deviceIdx
        ].detailData.bigquery.data[dataCategory][getInfo.period || 'noPeriod'] =
          Object.keys(newValue).length ? newValue : null;
      });
    }
  };

  getBigqueryDeviceDetailData = async (routerIdx, deviceIdx, deviceInfo) => {
    try {
      const dateFormatting = (type = 'current', dateFormat = null) => {
        const dateFormatResult = dateFormat
          ? `${this.accountStore.account.dateFormat.string}${dateFormat}`
          : this.accountStore.account.dateFormat.string;
        if (type.includes('+') || type.includes('-')) {
          const PERIOD = 0;
          const VALUE = 1;
          if (type.includes('+')) {
            const adjustment = type.split('+');
            return moment()
              .add(adjustment[VALUE], adjustment[PERIOD])
              .format(dateFormatResult);
          }
          const adjustment = type.split('-');
          return moment()
            .subtract(adjustment[VALUE], adjustment[PERIOD])
            .format(dateFormatResult);
        }

        if (type === 'start') {
          return moment().startOf('month').format(dateFormatResult);
        }
        if (type === 'end') {
          return moment().endOf('month').format(dateFormatResult);
        }
        return moment().format(dateFormatResult);
      };

      Object.keys(deviceInfo.deviceData?.getDataConfig)?.forEach(
        (dataTypeKey) => {
          deviceInfo.deviceData?.getDataConfig[dataTypeKey]?.forEach(
            async (getInfo) => {
              await getBigQueryApiResult({
                deviceSerial: deviceInfo.deviceData?.serial,
                start: dateFormatting(
                  getInfo.statDate.type,
                  getInfo.statDate.format,
                ),
                end: dateFormatting(
                  getInfo.endDate.type,
                  getInfo.endDate.format,
                ),
                type: dataTypeKey,
                data: getInfo.parameter,
                period: getInfo.period,
                processType: getInfo.processType,
                timezone: this.accountStore.account.timeZone,
                limit: getInfo.limit,
                sortType: getInfo.sortType,
                includeNull: getInfo.includeNull,
                reverse: getInfo.reverse,
              }).then((bigqueryApiResponse) => {
                if (Object.keys(bigqueryApiResponse).length) {
                  this.setBigqueryData(
                    routerIdx,
                    deviceIdx,
                    dataTypeKey,
                    getInfo,
                    bigqueryApiResponse,
                  );
                }
              });
            },
          );
        },
      );

      return false;
    } catch (error) {
      // console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getFirestoreDeviceDetailData = async (routerIdx, deviceIdx, deviceInfo) => {
    try {
      await getFirestoreQueryResult(deviceInfo.deviceData).then(
        (firestoreQueryRes) => {
          if (firestoreQueryRes.length) {
            firestoreQueryRes.forEach((byEachKeyDetailData) => {
              const snapshot = onSnapshot(
                byEachKeyDetailData,
                { includeMetadataChanges: true },
                (snapshot) => {
                  const dataCategory =
                    snapshot.query._query.path.segments[
                      snapshot.query._query.path.segments.length - 1
                    ];
                  const snapshotResult = snapshot.docs.map((doc) => {
                    if (doc.data().deviceId === deviceInfo.deviceData?.serial) {
                      return {
                        id: doc.id,
                        docData: doc.data(),
                      };
                    }
                    throw new Error('incorrect device id');
                  });

                  if (snapshotResult.length) {
                    if (
                      !this.dashboardFilteredData[routerIdx]?.devices[deviceIdx]
                        ?.detailData
                    ) {
                      runInAction(() => {
                        this.dashboardFilteredData[routerIdx].devices[
                          deviceIdx
                        ].detailData = { firestore: { data: {} } };
                      });
                    } else if (
                      !this.dashboardFilteredData[routerIdx]?.devices[deviceIdx]
                        ?.detailData.firestore
                    ) {
                      runInAction(() => {
                        this.dashboardFilteredData[routerIdx].devices[
                          deviceIdx
                        ].detailData.firestore = { data: {} };
                      });
                    }

                    const prevData =
                      this.dashboardFilteredData[routerIdx].devices[deviceIdx]
                        .detailData.firestore?.data?.[dataCategory];

                    if (prevData) {
                      Object.keys(snapshotResult[0]?.docData?.data).some(
                        (parameterKey) => {
                          // 길이가 다를 때 (처음에 데이터가 들어왔을때) 예외처리

                          if (
                            snapshotResult.length !==
                            prevData[parameterKey].length
                          ) {
                            snapshotResult.forEach((snapshotData, index) => {
                              Object.keys(snapshotData.docData.data).forEach(
                                (parameterKey) => {
                                  if (index === 0) {
                                    this.dashboardFilteredData[
                                      routerIdx
                                    ].devices[
                                      deviceIdx
                                    ].detailData.firestore.data[dataCategory][
                                      parameterKey
                                    ] = [];
                                  }
                                  this.dashboardFilteredData[routerIdx].devices[
                                    deviceIdx
                                  ].detailData.firestore.data[dataCategory][
                                    parameterKey
                                  ].unshift([
                                    new Date(
                                      snapshotData.docData.timestamp * 1000,
                                    ).getTime(),
                                    snapshotData.docData.data[parameterKey],
                                  ]);
                                },
                              );
                            });
                            return true;
                          }

                          if (
                            this.dashboardFilteredData[routerIdx].devices[
                              deviceIdx
                            ]?.detailData?.firestore?.data?.[dataCategory]?.[
                              parameterKey
                            ]?.[
                              this.dashboardFilteredData[routerIdx].devices[
                                deviceIdx
                              ].detailData.firestore.data[dataCategory][
                                parameterKey
                              ].length - 1
                            ][0] !==
                            snapshotResult[0].docData.timestamp * 1000
                          ) {
                            runInAction(() => {
                              this.dashboardFilteredData[routerIdx]?.devices?.[
                                deviceIdx
                              ]?.detailData?.firestore?.data?.[dataCategory]?.[
                                parameterKey
                              ]?.shift();
                              this.dashboardFilteredData[routerIdx]?.devices?.[
                                deviceIdx
                              ]?.detailData?.firestore?.data?.[dataCategory]?.[
                                parameterKey
                              ]?.push([
                                new Date(
                                  snapshotResult[0].docData.timestamp * 1000,
                                ).getTime(),
                                snapshotResult[0].docData.data[parameterKey],
                              ]);
                              return true;
                            });
                          }
                          return false;
                        },
                      );
                    } else {
                      if (
                        !this.dashboardFilteredData[routerIdx].devices[
                          deviceIdx
                        ]?.detailData?.firestore?.data
                      )
                        runInAction(() => {
                          this.dashboardFilteredData[routerIdx].devices[
                            deviceIdx
                          ].detailData.firestore.data = {};
                        });

                      runInAction(() => {
                        this.dashboardFilteredData[routerIdx].devices[
                          deviceIdx
                        ].detailData.firestore.data[dataCategory] = {};
                      });

                      runInAction(() => {
                        if (typeof snapshotResult === 'object') {
                          snapshotResult.forEach((snapshotData, index) => {
                            Object.keys(snapshotData.docData.data).forEach(
                              (parameterKey) => {
                                if (index === 0) {
                                  this.dashboardFilteredData[routerIdx].devices[
                                    deviceIdx
                                  ].detailData.firestore.data[dataCategory][
                                    parameterKey
                                  ] = [];
                                }
                                this.dashboardFilteredData[routerIdx].devices[
                                  deviceIdx
                                ].detailData.firestore.data[dataCategory][
                                  parameterKey
                                ]?.unshift([
                                  new Date(
                                    snapshotData.docData.timestamp * 1000,
                                  ).getTime(),
                                  snapshotData.docData.data[parameterKey],
                                ]);
                              },
                            );
                          });
                        }
                      });
                    }
                  }
                },
                (error) => {
                  /* firestore에 연동을 하기위한 권한에 문제가 있거나, query문 조합을 잘못 한 경우 */
                  return error;
                },
              );
              this.firestoreSnapshotArr.push(snapshot);
            });
          }
        },
      );
      return true;
    } catch (error) {
      return [500, { message: error }];
    }
  };

  clearFirestoreSnapshot = async () => {
    if (typeof this.firestoreSnapshotArr === 'object') {
      this.firestoreSnapshotArr.forEach((snapshot) => snapshot());
      this.firestoreSnapshotArr = [];
    }
  };

  getDetailData = async () => {
    if (this.dashboardFilteredData) {
      this.dashboardFilteredData.forEach((router, routerIdx) => {
        router.devices.forEach((device, deviceIdx) => {
          this.getBigqueryDeviceDetailData(routerIdx, deviceIdx, device);
          this.getFirestoreDeviceDetailData(routerIdx, deviceIdx, device);
        });
      });
    }
    return true;
  };

  getOnlyBigqueryData = async () => {
    if (this.dashboardFilteredData) {
      this.dashboardFilteredData.forEach((router, routerIdx) => {
        router.devices?.forEach((device, deviceIdx) => {
          this.getBigqueryDeviceDetailData(routerIdx, deviceIdx, device);
        });
      });
    }
  };

  setDevicesCommStatus = async (
    deviceIdx,
    routerIdx,
    connectStatus,
    lastConnectionTime,
  ) => {
    try {
      runInAction(() => {
        const formattedTime = lastConnectionTime
          ? moment(lastConnectionTime * 1000).format('YYYY-MM-DD HH:mm')
          : null;

        const successStatusObj = {
          status: connectStatus || false,
          lastCommTime: formattedTime,
        };

        this.dashboardFilteredData[routerIdx].devices[deviceIdx].detailData = {
          ...this.dashboardFilteredData[routerIdx].devices[deviceIdx]
            .detailData,
          ...successStatusObj,
        };
      });
    } catch {
      runInAction(() => {
        const falseStatusObj = {
          status: false,
          lastCommTime: '통신확인 설정 필요',
        };
        this.dashboardFilteredData[routerIdx].devices[deviceIdx].detailData = {
          ...this.dashboardFilteredData[routerIdx].devices[deviceIdx]
            ?.detailData,
          ...falseStatusObj,
        };
      });
    }
  };

  getDeviceCommStatus = async (deviceIdx, routerIdx, deviceSerial) => {
    try {
      if (!deviceSerial) return false;
      const res = await getDeviceConnection(deviceSerial);
      if (res.code) throw res;
      runInAction(() => {
        this.setDevicesCommStatus(
          deviceIdx,
          routerIdx,
          res.isConnected,
          res.lastConnectionTime,
        );
      });

      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  updateDeviceDBCommStatus = async (
    routerIdx,
    deviceIdx,
    type,
    isConnectionError = false,
  ) => {
    try {
      if (isConnectionError) throw new Error('통신 상태 에러');

      runInAction(() => {
        const lastConnectionTime =
          this.deviceConnStatus?.[deviceIdx]?.lastConnectionTime;
        const formattedTime = lastConnectionTime
          ? moment(lastConnectionTime * 1000).format('YYYY-MM-DD HH:mm')
          : null;

        const successStatusObj = {
          status: this.deviceConnStatus?.[deviceIdx]?.isConnected || false,
          lastCommTime: formattedTime,
        };

        if (type === this.BIGQUERY) {
          this.dashboardFilteredData[routerIdx].devices[
            deviceIdx
          ].detailData.bigquery = {
            ...this.dashboardFilteredData[routerIdx].devices[deviceIdx]
              .detailData.bigquery,
            ...successStatusObj,
          };
        } else {
          this.dashboardFilteredData[routerIdx].devices[
            deviceIdx
          ].detailData.firestore = {
            ...this.dashboardFilteredData[routerIdx].devices[deviceIdx]
              .detailData.firestore,
            ...successStatusObj,
          };
        }
      });
    } catch {
      runInAction(() => {
        const falseStatusObj = {
          status: false,
          lastCommTime: '통신확인 설정 필요',
        };
        if (type === this.BIGQUERY) {
          this.dashboardFilteredData[routerIdx].devices[
            deviceIdx
          ].detailData.bigquery = {
            ...this.dashboardFilteredData[routerIdx].devices[deviceIdx]
              ?.detailData.bigquery,
            ...falseStatusObj,
          };
        } else {
          this.dashboardFilteredData[routerIdx].devices[
            deviceIdx
          ].detailData.firestore = {
            ...this.dashboardFilteredData[routerIdx].devices[deviceIdx]
              ?.detailData.firestore,
            ...falseStatusObj,
          };
        }
      });
    }
  };
}

export default DeviceSummaryStore;
