import { BsFillLightningChargeFill } from 'react-icons/bs';

export const GetDataConfig = {
  // TODO : 사용하지 않는 파라메터(온도제어기)이므로 임시 주석처리
  sensors: [
    {
      statDate: { type: 'start' },
      endDate: { type: 'end' },
      parameter: 'CUMC,CUMT,D1,D2,DSP,RLCOMP,RLFAN,RLDEF,RLAUX,S1,S2,S3,S4',
      period: 1,
      processType: 'apexChart',
      limit: null,
      sortType: 'desc',
      includeNull: false,
      reverse: true,
    },
  ],
  managements: [
    {
      statDate: { type: 'start' },
      endDate: { type: 'end' },
      parameter: 'commStat',
      period: 1,
      processType: 'apexChart',
      limit: null,
      sortType: 'desc',
      includeNull: false,
      reverse: true,
    },
  ],
};

export const ParameterInfo = {
  sensors: {
    S1: {
      title: '고내 온도센서 (S1)',
      unit: '℃',
      icon: <BsFillLightningChargeFill />,
    },
    S2: {
      title: '제상 온도센서 (S2)',
      unit: '℃',
      icon: <BsFillLightningChargeFill />,
    },
    S3: {
      title: '보조 온도센서 (S3)',
      unit: '℃',
      icon: <BsFillLightningChargeFill />,
    },
    D1: {
      title: '디지털 입력 (DIG.IN1)',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    D2: {
      title: '디지털 입력 (DIG.IN2)',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    S4: {
      title: '보조 온도센서 (S4)',
      unit: '℃',
      icon: <BsFillLightningChargeFill />,
    },
    CUMT: {
      title: '콤프운전 누적시간',
      unit: '시',
      icon: <BsFillLightningChargeFill />,
    },
    CUMC: {
      title: '콤프운전 누적횟수',
      unit: '회',
      icon: <BsFillLightningChargeFill />,
    },
    DSP: {
      title: '디스플레이 상태 표시',
      unit: '',
      icon: <BsFillLightningChargeFill />,
    },
    RLCOMP: {
      title: 'COMP 출력 상태',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    RLFAN: {
      title: 'FAN 출력 상태',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    RLDEF: {
      title: 'DEF 출력 상태',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    RLAUX: {
      title: 'AUX 출력 상태',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    HTA: {
      title: '고온 알람',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    LTA: {
      title: '저온 알람',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    MA: {
      title: '기계 이상 알람',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    DA: {
      title: 'Door 알람',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    UA: {
      title: '사용자 알람',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    S1AA: {
      title: '센서 1 이상 알람',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    S2AA: {
      title: '센서 2 이상 알람',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    S3AA: {
      title: '센서 3 이상 알람',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
    S4AA: {
      title: '센서 4 이상 알람',
      unit: 'O/F',
      icon: <BsFillLightningChargeFill />,
    },
  },
  managements: {
    Status: {
      title: '현재 상태 알림',
      icon: <BsFillLightningChargeFill />,
    },
    ConnStat: {
      title: '인버터 통신 상태',
      label: ['정상', '에러'],
      icon: <BsFillLightningChargeFill />,
    },
    LineStat: {
      title: '계통 이상 상태',
      label: ['정상', '에러'],
      icon: <BsFillLightningChargeFill />,
    },
    InvPow: {
      title: '인버터 가동 상태',
      label: ['가동중', '정지'],
      icon: <BsFillLightningChargeFill />,
    },
    InvSafety: {
      title: '인버터 Safety 보호 회로',
      icon: <BsFillLightningChargeFill />,
    },
  },
  control: {},
};

export const FirebaseConfig = ['control', 'sensors', 'managements'];

export default ParameterInfo;
