import axios from 'src/utils/axios';
import imageToBlob from 'src/utils/imageToBlob';

export const postDrawingInfo = async (drawingImage, drawingName) => {
  const formData = new FormData();

  formData.append('drawingImage', imageToBlob(drawingImage));
  formData.append('name', drawingName);

  const res = await axios.post('edmu/device/drawing', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res.data;
};

export const getAllDrawingInfo = async () => {
  const res = await axios.get('edmu/device/drawing');
  return res.data;
};

export const getDrawingInfo = async (drawingId) => {
  const res = await axios.get('edmu/device/drawing', {
    params: {
      id: drawingId,
    },
  });
  return res.data;
};

export const patchDrawingInfo = async (
  drawingId,
  drawingImage,
  drawingName,
) => {
  const formData = new FormData();

  formData.append('drawingImage', imageToBlob(drawingImage));
  formData.append('name', drawingName);

  const res = await axios.patch(
    `edmu/device/drawing?id=${drawingId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return res.data;
};

export const deleteDrawingInfo = async (drawingId) => {
  const res = await axios.delete(`edmu/device/drawing?id=${drawingId}`);
  return res;
};

export const postDrawingDevice = async (deviceDrawingId, dataInfo) => {
  const res = await axios.post(`edmu/device/drawing/info`, {
    deviceDrawingId: deviceDrawingId,
    deviceId: dataInfo.deviceId,
    xCoordinate: dataInfo.xCoordinate,
    yCoordinate: dataInfo.yCoordinate,
    deviceParameter: dataInfo.deviceParameter,
  });
  return res;
};

export const patchDrawingDevice = async (deviceDrawingId, dataInfo) => {
  const res = await axios.patch(
    `edmu/device/drawing/info?id=${deviceDrawingId}`,
    {
      xCoordinate: dataInfo.xCoordinate,
      yCoordinate: dataInfo.yCoordinate,
      deviceParameter: dataInfo.deviceParameter,
    },
  );
  return res;
};

export const deleteDrawingDevice = async (drawingId) => {
  const res = await axios.delete(`edmu/device/drawing/info?id=${drawingId}`);
  return res;
};

export const getDataKey = async (typeId) => {
  const response = await axios.get('edmu/device-type/key', {
    params: {
      'key-type': 'sensors,managements,control',
      type: typeId,
      'include-info': true,
    },
  });
  return response.data;
};

export const getDrawingLogData = async (
  checkedAlertValue,
  deviceList,
  timezone,
) => {
  const response = await axios.get(`edmu/log`, {
    params: {
      'alert-type': checkedAlertValue.join(','),
      'event-group': 'deviceTimelineEvent',
      'device-serial': deviceList,
      'time-zone': timezone,
    },
  });
  return response.data;
};
