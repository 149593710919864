import axios from 'src/utils/axios';
import imageToBlob from 'src/utils/imageToBlob';

export const postNewInspectionHistory = async (newInspectionInfor) => {
  try {
    const formData = new FormData();
    formData.append('deviceId', newInspectionInfor.deviceData.id);
    formData.append('workerId', newInspectionInfor.workerId);
    formData.append('title', newInspectionInfor.title);
    formData.append('timeZone', newInspectionInfor.timeZone);
    formData.append('maintenanceDate', newInspectionInfor.maintenanceDate);

    if (newInspectionInfor.description)
      formData.append('description', newInspectionInfor.description);
    if (newInspectionInfor.checkItems.length) {
      newInspectionInfor.checkItems.forEach((eachCheckItemData, itemIndex) => {
        formData.append(
          `checkItems[${itemIndex}][checklistItem]`,
          eachCheckItemData.checklistItem,
        );
        formData.append(
          `checkItems[${itemIndex}][checkContent]`,
          eachCheckItemData.checkContent,
        );
        formData.append(
          `checkItems[${itemIndex}][checkResult]`,
          eachCheckItemData.checkResult,
        );
        if (eachCheckItemData.imageKey.length) {
          eachCheckItemData.imageKey.forEach((eachImageKey, keyIndex) => {
            formData.append(
              `checkItems[${itemIndex}][imageKey][${keyIndex}]`,
              eachImageKey,
            );
          });
        }
      });
    }
    if (newInspectionInfor.regularMaintenanceImage.length) {
      newInspectionInfor.regularMaintenanceImage.forEach((eachImageData) => {
        formData.append(
          'regularMaintenanceImage',
          imageToBlob(eachImageData.image),
          eachImageData.name,
        );
      });
    }
    const res = await axios.post(
      'edmu/device/regular-maintenance-history',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllInspectionHistories = async (requestInfor) => {
  const res = await axios.get('edmu/device/regular-maintenance-history', {
    params: {
      'device-id': requestInfor?.deviceId || null,
      start: requestInfor?.start || null,
      end: requestInfor?.end || null,
      'time-zone': requestInfor?.timeZone || null,
      'sort-type': requestInfor?.sortType || null,
      limit: requestInfor?.limit || null,
    },
  });
  return res.data;
};

export const getInspectionHistoryById = async (requestInfor) => {
  const res = await axios.get('edmu/device/regular-maintenance-history', {
    params: {
      id: requestInfor?.historyId,
      'device-id': requestInfor?.deviceId || null,
      start: requestInfor?.start || null,
      end: requestInfor?.end || null,
      'time-zone': requestInfor?.timeZone || null,
      'sort-type': requestInfor?.sortType || null,
      limit: requestInfor?.limit || null,
    },
  });
  return res.data;
};

export const patchInspectionHistory = async (modifyInfo) => {
  const res = await axios.patch(
    `edmu/device/regular-maintenance-history?id=${modifyInfo.id}`,
    { ...modifyInfo.data, timeZone: modifyInfo.timeZone },
  );
  return res;
};

export const deleteInspectionHistory = async (historyId) => {
  const res = await axios.delete(
    `edmu/device/regular-maintenance-history?id=${historyId}`,
  );
  return res;
};

export const postInspectionImage = async (dataInfor) => {
  const formData = new FormData();

  if (dataInfor.images?.length) {
    dataInfor.images.forEach((eachImageData) => {
      formData.append('includeKey', true);
      formData.append(
        'image',
        imageToBlob(eachImageData.image),
        eachImageData.name,
      );
    });
  }

  const res = await axios.post(
    `edmu/image/regular-maintenance-history?type-id=${dataInfor.historyId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return res;
};

export const deleteInspectionImage = async (imageId) => {
  const res = await axios.delete(`edmu/image?id=${imageId}`);
  return res;
};
