import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/mobx-store';
import { ListSubheader, List, Divider } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MonitorIcon from '@mui/icons-material/Monitor';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MapIcon from '@mui/icons-material/Map';
import FactoryIcon from '@mui/icons-material/Factory';
import DevicesIcon from '@mui/icons-material/Devices';
import HandymanIcon from '@mui/icons-material/Handyman';
import InventoryIcon from '@mui/icons-material/Inventory';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SidebarMenuItem from './item';
import {
  SubMenuWrapper,
  MenuWrapper,
  SubMenuDeviceWrapper,
  OuterSubMenuWrapper,
} from '../../style';

const SidebarMenu = observer(() => {
  const location = useLocation();
  const { t } = useTranslation();
  const { deviceStore, utilStore } = useStore();
  const {
    bookmarkedDevices,
    getSideBarDeviceList,
    getBookmarkedDevices,
    getDevices,
    getDeviceTypes,
  } = deviceStore;
  const { sidebarOpen } = utilStore;

  const renderSingleMenu = ({ items, path }) => (
    <OuterSubMenuWrapper sidebarOpen={sidebarOpen}>
      <List component="div">
        {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
      </List>
    </OuterSubMenuWrapper>
  );

  const renderChildrenMenu = ({ items, path }) => (
    <SubMenuWrapper>
      <List component="div">
        {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
      </List>
    </SubMenuWrapper>
  );

  const renderParentMenu = ({ items, path }) => (
    <SubMenuDeviceWrapper sidebarOpen={sidebarOpen}>
      <List component="div">
        {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
      </List>
    </SubMenuDeviceWrapper>
  );

  const reduceChildRoutes = ({ ev, path, item }) => {
    const key = item.name;
    const exactMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: true,
          },
          path,
        )
      : false;
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false,
          },
          path,
        )
      : false;

    if (item.items) {
      // 하위 메뉴를 포함한 경우
      if (!item.hasParent && item.hasChildren) {
        // 최상단 메뉴의 경우
        ev.push(
          <SidebarMenuItem
            key={key}
            active={partialMatch}
            open={partialMatch}
            name={item.name}
            icon={item.icon}
            link={item.link}
            hasParent={item.hasParent}
            hasChildren={item.hasChildren}
          >
            {renderParentMenu({
              path,
              items: item.items,
            })}
          </SidebarMenuItem>,
        );
      } else {
        // 중간 메뉴의 경우
        ev.push(
          <SidebarMenuItem
            key={key}
            active={partialMatch}
            open={partialMatch}
            name={item.name}
            icon={item.icon}
            link={item.link}
            hasParent={item.hasParent}
            hasChildren={item.hasChildren}
          >
            {renderChildrenMenu({
              path,
              items: item.items,
            })}
          </SidebarMenuItem>,
        );
      }
    } else {
      // 단일 메뉴 및 최하단 메뉴인 경우
      ev.push(
        <SidebarMenuItem
          key={key}
          active={exactMatch}
          open={partialMatch}
          name={item.name}
          icon={item.icon}
          link={item.link}
          badge={item.badge}
          badgeTooltip={item.badgeTooltip}
          hasParent={item.hasParent}
          hasChildren={item.hasChildren}
        />,
      );
    }

    return ev;
  };

  const [menuInfo, setMenuInfo] = useState([
    {
      heading: 'Monitoring',
      items: [
        {
          name: '대시보드',
          icon: <MonitorIcon />,
          link: '/monitoring/dashboard',
        },
        {
          name: '장비 현황판',
          icon: <MapIcon />,
          link: '/monitoring/status-board',
        },
        {
          name: '장비 카드',
          icon: <CreditCardIcon />,
          link: '/monitoring/card',
        },
        {
          name: '즐겨찾기',
          icon: <BookmarkIcon />,
          hasParent: false,
          hasChildren: true,
        },
        // {
        //   name: '장비 요약',
        //   icon: <AutoAwesomeMosaicIcon />,
        //   link: '/monitoring/summary',
        //   hasParent: false,
        //   hasChildren: false,
        // },
      ],
    },
    {
      heading: 'Management',
      items: [
        {
          name: '장비 관리',
          icon: <DevicesIcon />,
          link: '/monitoring/device-list',
          hasParent: false,
          hasChildren: false,
        },
        {
          name: '수리 이력 관리',
          icon: <HandymanIcon />,
          link: '/monitoring/maintenance-list',
          hasParent: false,
          hasChildren: false,
        },
        {
          name: '정기점검 이력',
          icon: <FactCheckIcon />,
          link: '/monitoring/inspection-list',
          hasParent: false,
          hasChildren: false,
        },
        {
          name: '업체 관리',
          icon: <FactoryIcon />,
          link: '/manufacturing/manufacturer',
          hasParent: false,
          hasChildren: false,
        },
        {
          name: '제품 관리',
          icon: <InventoryIcon />,
          link: '/manufacturing/product-list',
          hasParent: false,
          hasChildren: false,
        },
      ],
    },
  ]);

  useEffect(() => {
    // getDeviceTypes();
    if (bookmarkedDevices) {
      let copyMenu = [...menuInfo];
      copyMenu.forEach((upperMenuList, upperMenuIndex) => {
        upperMenuList.items.forEach((lowerMenuList, lowerMenuIndex) => {
          if (lowerMenuList.name === '즐겨찾기') {
            copyMenu[upperMenuIndex].items[lowerMenuIndex].items =
              getSideBarDeviceList();
          }
        });
      });

      setMenuInfo(copyMenu);
    } else {
      getDeviceTypes();
      getDevices();
      getBookmarkedDevices();
    }
  }, [bookmarkedDevices]);

  return menuInfo.map((section, index) => (
    <MenuWrapper key={section.heading}>
      <List
        component="div"
        subheader={
          sidebarOpen ? (
            <ListSubheader disableSticky>{t(section.heading)}</ListSubheader>
          ) : (
            <Divider
              className={`sidebar_divider ${!index && 'first_divider'}`}
            />
          )
        }
      >
        {section.items &&
          renderSingleMenu({
            items: section.items,
            path: location.pathname,
          })}
      </List>
    </MenuWrapper>
  ));
});

export default SidebarMenu;
