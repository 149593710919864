import { BsFillLightningChargeFill } from 'react-icons/bs';

export const GetDataConfig = {
  sensors: [
    {
      statDate: { type: 'current' },
      endDate: { type: 'current' },
      parameter: 'PVV,PVA,PVW,RSV,STV,TRV,RA,SA,TA,CVI,PF,Freq,AGE,DGE,EFF',
      period: 5,
      processType: 'apexChart',
      limit: 13,
      sortType: 'desc',
      includeNull: false,
      reverse: true,
    },
    {
      statDate: { type: 'current', format: 'T00:00' },
      endDate: { type: 'd+1', format: 'T00:00' },
      parameter: 'PVV,PVA,PVW,AGE,CVI',
      period: 10,
      processType: 'apexChart',
      limit: null,
      sortType: 'asc',
      includeNull: true,
      reverse: false,
    },
    {
      statDate: { type: 'start' },
      endDate: { type: 'end' },
      parameter: 'PGE',
      period: 'day',
      processType: 'apexChart',
      limit: 31,
      sortType: 'asc',
      includeNull: true,
      reverse: false,
    },
    {
      statDate: { type: 'start' },
      endDate: { type: 'end' },
      parameter: 'PGE',
      period: 'month',
      processType: 'apexChart',
      limit: 1,
      sortType: 'asc',
      includeNull: true,
      reverse: false,
    },
  ],
};

export const ParameterInfo = {
  sensors: {
    PVV: {
      title: 'PV 전압',
      unit: 'V',
      icon: <BsFillLightningChargeFill />,
    },
    PVA: {
      title: 'PV 전류',
      unit: 'A',
      icon: <BsFillLightningChargeFill />,
    },
    PVW: {
      title: 'PV 전력',
      unit: 'kW',
      icon: <BsFillLightningChargeFill />,
    },
    RSV: {
      title: '계통 R-S 전압',
      unit: 'V',
      icon: <BsFillLightningChargeFill />,
    },
    STV: {
      title: '계통 S-T 전압',
      unit: 'V',
      icon: <BsFillLightningChargeFill />,
    },
    TRV: {
      title: '계통 T-R 전압',
      unit: 'V',
      icon: <BsFillLightningChargeFill />,
    },
    RA: {
      title: '계통 R 전류',
      unit: 'A',
      icon: <BsFillLightningChargeFill />,
    },
    SA: {
      title: '계통 S 전류',
      unit: 'A',
      icon: <BsFillLightningChargeFill />,
    },
    TA: {
      title: '계통 T 전류',
      unit: 'A',
      icon: <BsFillLightningChargeFill />,
    },
    CVI: {
      title: '계통 전력',
      unit: 'kW',
      icon: <BsFillLightningChargeFill />,
    },
    PF: {
      title: '역률',
      unit: '%',
      icon: <BsFillLightningChargeFill />,
    },
    Freq: {
      title: '주파수',
      unit: 'Hz',
      icon: <BsFillLightningChargeFill />,
    },
    AGE: {
      title: '총 누적 발전량',
      unit: 'kWh',
      icon: <BsFillLightningChargeFill />,
    },
    DGE: {
      title: '금일 누적 발전량',
      unit: 'kWh',
      icon: <BsFillLightningChargeFill />,
    },
    PGE: {
      title: '기간별 누적 발전량',
      unit: 'kWh',
      icon: <BsFillLightningChargeFill />,
    },
    EFF: {
      title: '인버터 효율',
      unit: '%',
      icon: <BsFillLightningChargeFill />,
    },
  },
  managements: {
    Status: {
      title: '현재 상태 알림',
      icon: <BsFillLightningChargeFill />,
    },
    commStat: {
      title: '인버터 통신 상태',
      label: ['에러', '정상'],
      icon: <BsFillLightningChargeFill />,
    },
    LineStat: {
      title: '계통 상태',
      label: ['정상', '에러'],
      icon: <BsFillLightningChargeFill />,
    },
    InvPow: {
      title: '인버터 가동 상태',
      label: ['가동중', '정지'],
      icon: <BsFillLightningChargeFill />,
    },
    InvSafety: {
      title: '인버터 Safety 보호 회로',
      icon: <BsFillLightningChargeFill />,
    },
  },
};

export const FirebaseConfig = ['control', 'sensors', 'managements'];
export default ParameterInfo;
