import { Box, Card, Typography, Container, Button, Grid } from '@mui/material';
import PageHelmet from 'src/components/PageHelmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MaintenanceLogo } from 'src/assets/images/maintenance.svg';
import Logo from 'src/components/Logo';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { MainContent, LanguageSwitcherWrapper } from '../style';

const NetworkError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const refresh = () => {
    window.location.reload();
  };

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <>
      <PageHelmet message={'네트워크 에러'} />
      <LanguageSwitcherWrapper>
        <LanguageSwitcher />
      </LanguageSwitcherWrapper>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center" justifyContent="center">
            <MaintenanceLogo className="main_image network_error_img" />
            <Typography variant="h2" className="title">
              {t('서버에 연결할 수 없습니다')}
            </Typography>
            <Typography
              variant="h4"
              className="text_content"
              color="text.secondary"
              fontWeight="normal"
            >
              {t(
                '네트워크 및 방화벽 확인 후 이 오류가 반복되면 관리자에게 문의하세요.',
              )}
            </Typography>
          </Box>
          <Container maxWidth="sm">
            <Card className="card_box">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    className="text_content"
                    onClick={refresh}
                    variant="outlined"
                    fullWidth
                  >
                    {t('새로고침')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    className="text_content"
                    onClick={handleGoHome}
                    variant="outlined"
                    fullWidth
                  >
                    {t('메인 페이지')}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Container>
        <Logo />
      </MainContent>
    </>
  );
};

export default NetworkError;
