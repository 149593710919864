import { useRoutes, useLocation } from 'react-router-dom';
import router from 'src/router';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/mobx-store';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import useAuth from 'src/hooks/useAuth';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import Alert from 'src/components/Alert';
import AppInit from './components/AppInit';
import { useEffect } from 'react';
import { edmuSocket } from './utils/socket.io';
import 'src/App.css';

const App = observer(() => {
  const content = useRoutes(router);
  const auth = useAuth();
  const location = useLocation().pathname;
  const { deviceSummaryStore, alertStore } = useStore();
  const { alertOpen } = alertStore;
  const { clearFirestoreSnapshot } = deviceSummaryStore;

  useEffect(() => {
    edmuSocket.on(`frontVersionUpdate`, () => {
      window.location.reload(true);
    });
    document.title = 'Okomo IoT Service';
  }, []);

  useEffect(() => {
    const clearSnapshot = async () => {
      // unsuscribe firestore snapshot in summary page
      if (location !== '/monitoring/summary') await clearFirestoreSnapshot();
    };

    clearSnapshot();
  }, [location]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/* 추후  다중 알림 메시지 처리를 위해 SnackbarProvider는 지우지 말고 주석으로 남겨주세요 */}
        {/* <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        > */}
        <CssBaseline />
        {auth.isInitialized ? content : <AppInit />}
        {/* </SnackbarProvider> */}

        {/* snackbar provider를 사용하지 않고, 임시로 단일 메시지 출력을 위해 처리 */}
        {alertOpen && <Alert />}
      </LocalizationProvider>
    </ThemeProvider>
  );
});
export default App;
