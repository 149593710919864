import { Navigate } from 'react-router-dom';
import { Loader, retryLazy } from 'src/utils/lazyUtil';

const Dashboard = Loader(
  retryLazy(() => import('src/pages/monitoring/Dashboard')),
);

const StatusBoard = Loader(
  retryLazy(() => import('src/pages/monitoring/StatusBoard')),
);

const Summary = Loader(retryLazy(() => import('src/pages/monitoring/Summary')));

const DeviceDetail = Loader(
  retryLazy(() => import('src/pages/monitoring/DeviceDetail')),
);

const DeviceList = Loader(
  retryLazy(() => import('src/pages/monitoring/DeviceList')),
);

const MaintenanceList = Loader(
  retryLazy(() => import('src/pages/monitoring/MaintenanceList')),
);

const MaintenanceDetail = Loader(
  retryLazy(() => import('src/pages/monitoring/MaintenanceDetail')),
);

const InspectionList = Loader(
  retryLazy(() => import('src/pages/monitoring/InspectionList')),
);

const InspectionDetail = Loader(
  retryLazy(() => import('src/pages/monitoring/InspectionDetail')),
);

const CardBoard = Loader(
  retryLazy(() => import('src/pages/monitoring/CardBoard')),
);

const monitoringRoutes = [
  {
    path: '',
    element: <Navigate to="dashboard" replace />,
  },
  {
    path: 'dashboard',
    element: <Dashboard />,
  },
  {
    path: 'status-board',
    element: <StatusBoard />,
  },
  {
    path: 'card',
    element: <CardBoard />,
  },
  {
    path: 'summary',
    element: <Summary />,
  },
  {
    path: 'device-list',
    element: <DeviceList />,
  },
  {
    path: 'device/:type/:serial',
    element: <DeviceDetail />,
  },
  {
    path: 'maintenance-list',
    element: <MaintenanceList />,
  },
  {
    path: 'maintenance/:type/:serial/:logId',
    element: <MaintenanceDetail />,
  },
  {
    path: 'inspection-list',
    element: <InspectionList />,
  },
  {
    path: 'inspection/:type/:serial/:historyId',
    element: <InspectionDetail />,
  },
  {
    path: 'setting',
    children: [
      {
        path: '',
        element: <Navigate to="device" replace />,
      },
    ],
  },
];

export default monitoringRoutes;
