import PropTypes from 'prop-types';
import { LabelWrapper } from './style';

const Label = ({ className, color = 'secondary', children, ...rest }) => {
  return (
    <LabelWrapper
      className={
        className ? 'MuiLabel-' + color + ' ' + className : 'MuiLabel-' + color
      }
      {...rest}
    >
      {children}
    </LabelWrapper>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'black',
    'secondary',
    'error',
    'warning',
    'success',
    'info',
  ]),
};

export default Label;
