import { useState, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Collapse, Divider, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { MenuListItem, LightTooltip, TooltipContentButton } from '../../style';
import { useStore } from 'src/mobx-store';
import { observer } from 'mobx-react-lite';

const SidebarMenuItem = observer(
  ({
    children,
    link,
    icon: Icon,
    badge,
    badgeTooltip,
    open: openParent,
    active,
    name,
    ...rest
  }) => {
    const [collapseStatus, setCollapseStatus] = useState(openParent);
    const mobile = useMediaQuery('(max-width: 784px)');
    const { t } = useTranslation();
    const { utilStore } = useStore();
    const { sidebarOpen, setSidebarOpen } = utilStore;
    const hasChildren = rest.hasChildren;
    const hasParent = rest.hasParent;
    const hasCollapse = children && hasChildren && hasParent;

    useEffect(() => {
      if (!sidebarOpen && hasChildren && !hasParent) {
        setCollapseStatus(false);
      }
    }, [sidebarOpen]);

    const toggleMenu = () => {
      if (sidebarOpen || (hasChildren && hasParent)) {
        setCollapseStatus(!collapseStatus);
      }
    };

    return (
      <LightTooltip
        placement={children ? 'bottom' : 'right'}
        arrow
        title={
          <div className="tooltip_title_div">
            {children ? (
              <>
                <div className="dropdown_menu_label">{t(name)}</div>
                <Divider />
                {children}
              </>
            ) : (
              <TooltipContentButton
                disableRipple
                component={RouterLink}
                to={link}
                reloadDocument={openParent}
              >
                {t(name)}
              </TooltipContentButton>
            )}
          </div>
        }
        leaveDelay={children && 220}
        disableHoverListener={sidebarOpen || hasCollapse || hasParent}
        disableFocusListener={children}
        disableTouchListener
      >
        <MenuListItem
          component="div"
          className={children && 'Mui-children'}
          key={name}
          {...rest}
          dataName={!children && name}
          sidebarOpen={sidebarOpen}
          parentState={hasParent}
        >
          {children ? (
            <>
              <Button
                className={clsx(collapseStatus)}
                startIcon={Icon}
                endIcon={
                  collapseStatus ? (
                    <ExpandLessTwoToneIcon />
                  ) : (
                    <ExpandMoreTwoToneIcon />
                  )
                }
                onClick={toggleMenu}
              >
                <span className={!sidebarOpen && 'sidebar_dropdown_menu'}>
                  {(sidebarOpen && t(name)) ||
                    (hasParent && hasChildren && t(name))}
                </span>
              </Button>
              <Collapse in={collapseStatus}>{children}</Collapse>
            </>
          ) : (
            <Button
              disableRipple
              component={RouterLink}
              to={link}
              reloadDocument={openParent}
              startIcon={Icon}
              onClick={() => mobile && setSidebarOpen(false)}
            >
              <span className={!sidebarOpen && 'sidebar_dropdown_menu'}>
                {sidebarOpen ? t(name) : hasParent && t(name)}
              </span>
            </Button>
          )}
        </MenuListItem>
      </LightTooltip>
    );
  },
);

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.any,
  badge: PropTypes.string,
  badgeTooltip: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false,
};

export default SidebarMenuItem;
