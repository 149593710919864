import { makeAutoObservable } from 'mobx';
import { CONTROL_STATUS } from 'src/assets/constants';

class EdmuStore {
  alertStore;

  utilStore;

  deviceStore;

  devicesUnderControl = [];

  devicesUnderTimeout = [];

  errorSlaves = {
    onlyExistInSlaveData: [], // 연결 정보가 파이어베이스에만 존재하는 경우
    onlyExistInDatabase: [], // 연결 정보가 서버에만 존재하는 경우
  };

  agGridAction = {
    connectedGrdiRefreshState: false,
    unconnectedGrdiRefreshState: false,
    connectedDeviceId: null,
    unconnectedDeviceId: null,
    connectedGridPageForce: null,
    unconnectedGridPageForce: null,
    selectedDeviceData: null,
    connectedProtocolId: null,
    connectedPortNo: null,
  };

  constructor({ alertStore, utilStore, deviceStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
    this.utilStore = utilStore;
    this.deviceStore = deviceStore;
  }

  setDevicesUnderControl = (devicesUnderControlList) => {
    this.devicesUnderControl = devicesUnderControlList;
  };

  setDevicesUnderTimeout = (devicesUnderTimeoutList) => {
    this.devicesUnderTimeout = devicesUnderTimeoutList;
  };

  setErrorSlaves = (errorSlavesList) => {
    this.errorSlaves.onlyExistInSlaveData =
      errorSlavesList.onlyExistInSlaveData;
    this.errorSlaves.onlyExistInDatabase = errorSlavesList.onlyExistInDatabase;
  };

  setAgGridAction = (type, value) => {
    switch (type) {
      case 'connectedGridRefresh':
        this.agGridAction.connectedGrdiRefreshState = value;
        break;

      case 'unconnectedGridRefresh':
        this.agGridAction.unconnectedGrdiRefreshState = value;
        break;

      case 'connectedDeviceId':
        this.agGridAction.connectedDeviceId = value;
        break;

      case 'connectedProtocolId':
        this.agGridAction.connectedProtocolId = value;
        break;

      case 'connectedPortNo':
        this.agGridAction.connectedPortNo = value;
        break;

      case 'unconnectedDeviceId':
        this.agGridAction.unconnectedDeviceId = value;
        break;

      case 'connectedGridPageForce':
        this.agGridAction.connectedGridPageForce = value;
        break;

      case 'unconnectedGridPageForce':
        this.agGridAction.unconnectedGridPageForce = value;
        break;

      case 'selectedDeviceData':
        this.agGridAction.selectedDeviceData = value;
        break;

      default:
        console.error('type invalid');
        break;
    }
  };

  getControlKeyStatusList = (routerSerial) => {
    return new Promise((resolve) => {
      this.deviceStore
        .getWebsocketEvnet(routerSerial)
        .then((websocketHistoryData) => {
          const initDevicesUnderControl = [];
          const initDevicesUnderTimeout = [];
          if (websocketHistoryData) {
            websocketHistoryData.forEach((eachSocketData) => {
              const splitApplyData = eachSocketData?.key?.split(',');
              const selectedDeviceSerial = splitApplyData?.[1];
              const isSlave = splitApplyData?.[0]?.includes('slave.');
              const controlType = isSlave ? splitApplyData?.pop() : 'edit';
              const controlStatus = eachSocketData?.state;
              const controlValue = eachSocketData?.controlValue;
              const controlKey = splitApplyData?.[0]?.split('.')?.[1]
                ? isSlave
                  ? splitApplyData?.[0]?.split('.')?.[1]
                  : `${splitApplyData?.[0]?.split('.')?.[1]}.${
                      splitApplyData?.[1]
                    }`
                : eachSocketData?.key;

              const checkDeviceSerial = isSlave
                ? selectedDeviceSerial
                : routerSerial;

              let checkExistIndex;

              switch (controlStatus) {
                case CONTROL_STATUS.LOADING:
                  checkExistIndex = initDevicesUnderControl.findIndex(
                    (eachDeviceData) =>
                      eachDeviceData?.deviceSerial === checkDeviceSerial,
                  );

                  if (checkExistIndex < 0) {
                    initDevicesUnderControl.push({
                      id:
                        this.deviceStore.devices.find(
                          (eachDeviceData) =>
                            eachDeviceData.serial === checkDeviceSerial,
                        )?.id || `ERROR${checkDeviceSerial}`,
                      deviceSerial: checkDeviceSerial,
                      controlKey: [controlKey],
                      controlType: [controlType],
                      controlValue: [controlValue],
                    });
                  } else {
                    initDevicesUnderControl[checkExistIndex].controlKey.push(
                      controlKey,
                    );
                    initDevicesUnderControl[checkExistIndex].controlType.push(
                      controlType,
                    );
                    initDevicesUnderControl[checkExistIndex].controlValue.push(
                      controlValue,
                    );
                  }
                  break;

                case CONTROL_STATUS.TIMEOUT:
                  checkExistIndex = initDevicesUnderTimeout.findIndex(
                    (eachDeviceData) =>
                      eachDeviceData?.deviceSerial === checkDeviceSerial,
                  );
                  if (checkExistIndex < 0) {
                    initDevicesUnderTimeout.push({
                      id: this.deviceStore.devices.find(
                        (eachDeviceData) =>
                          eachDeviceData.serial === checkDeviceSerial,
                      )?.id,
                      deviceSerial: checkDeviceSerial,
                      controlKey: [controlKey],
                      controlType: [controlType],
                      controlValue: [controlValue],
                    });
                  } else {
                    initDevicesUnderTimeout[checkExistIndex].controlKey.push(
                      controlKey,
                    );
                    initDevicesUnderTimeout[checkExistIndex].controlType.push(
                      controlType,
                    );
                    initDevicesUnderTimeout[checkExistIndex].controlValue.push(
                      controlValue,
                    );
                  }
                  break;

                default:
                  break;
              }
            });
            this.setDevicesUnderControl(initDevicesUnderControl);
            this.setDevicesUnderTimeout(initDevicesUnderTimeout);
            this.setAgGridAction('unconnectedGridRefresh', true);
            this.setAgGridAction('connectedGridRefresh', true);
            resolve(true);
          }
        });
    });
  };

  initErrorSlavesList = async (routerSerial) => {
    this.deviceStore.getSlaveDataError(routerSerial).then((data) => {
      let initOnlyExistInSlaveData = [];
      let initOnlyExistInDatabase = [];

      data.onlyExistInDatabase?.forEach((testData) => {
        initOnlyExistInDatabase.push(testData);
      });

      data.onlyExistInSlaveData?.forEach((testData) => {
        initOnlyExistInSlaveData.push(testData);
      });

      this.setErrorSlaves({
        onlyExistInSlaveData: initOnlyExistInSlaveData,
        onlyExistInDatabase: initOnlyExistInDatabase,
      });
      this.setAgGridAction('unconnectedGridRefresh', true);
      this.setAgGridAction('connectedGridRefresh', true);
    });
  };

  // 라우터 통신 설정에 대한 웹소켓 응답 처리
  applyCommSettingChanges = async (serial, socketData, controlStatus) => {
    switch (controlStatus) {
      case CONTROL_STATUS.LOADING:
        await this.getControlKeyStatusList(serial).then(() => {
          this.setAgGridAction('unconnectedGridPageForce', null);
          this.setAgGridAction('connectedGridPageForce', null);
        });
        break;

      case CONTROL_STATUS.TIMEOUT:
        await this.getControlKeyStatusList(serial).then(() => {
          this.setAgGridAction('selectedDeviceData', null);
          this.setAgGridAction('unconnectedGridPageForce', null);
          this.setAgGridAction('connectedGridPageForce', null);
          this.alertStore.setAlertOpen(
            'error',
            '장비 제어 명령이 실패했습니다.',
          );
        });
        break;

      case CONTROL_STATUS.IDLE:
        await this.getControlKeyStatusList(serial).then(async () => {
          let controlStatusList = [];
          socketData?.forEach((eachSocketData) => {
            const splitApplyData = eachSocketData?.key?.split(',');
            const isSlave = splitApplyData?.[0]?.includes('slave.');
            const controlKey = splitApplyData?.[0]?.split('.')?.[1];

            const controlType = isSlave ? splitApplyData?.pop() : 'edit';
            const selectedDeviceSerial =
              splitApplyData?.[1] || eachSocketData?.key;
            const controlValue = eachSocketData.changeValue;

            if (
              !controlStatusList.find((groupingData) =>
                isSlave
                  ? groupingData?.deviceSerial === selectedDeviceSerial
                  : groupingData?.deviceSerial === serial,
              )
            ) {
              controlStatusList.push({
                deviceSerial: isSlave ? selectedDeviceSerial : serial,
                controlType,
                controlResult: [{ key: controlKey, value: controlValue }],
              });
            } else {
              const existGroupArray = controlStatusList.find((existData) =>
                isSlave
                  ? existData.deviceSerial === selectedDeviceSerial
                  : existData.deviceSerial === serial,
              );
              existGroupArray.controlResult.push({
                key: controlKey,
                value: controlValue,
              });
            }
          });
          Promise.all(controlStatusList).then((totalGropuingData) => {
            totalGropuingData.forEach(async (eachGroupingData) => {
              const deviceSerial = eachGroupingData?.deviceSerial;
              const controlType = eachGroupingData?.controlType;
              const controlResult = eachGroupingData?.controlResult;

              if (
                !this.devicesUnderControl?.find(
                  (eachUnderControlDevice) =>
                    eachUnderControlDevice?.deviceSerial === deviceSerial,
                )
              ) {
                const selectedDeviceInfo = this.deviceStore.devices?.find(
                  (deviceData) => deviceData.serial === deviceSerial,
                );
                switch (controlType) {
                  case 'add':
                    controlResult.forEach((eachResult) => {
                      switch (eachResult.key) {
                        case 'protocolId':
                          this.setAgGridAction(
                            `connectedProtocolId`,
                            eachResult.value,
                          );
                          break;

                        case 'portNo':
                          this.setAgGridAction(
                            `connectedPortNo`,
                            eachResult.value,
                          );
                          break;

                        default:
                          break;
                      }
                    });
                    this.setAgGridAction(
                      'connectedDeviceId',
                      selectedDeviceInfo?.id,
                    );
                    break;

                  case 'edit':
                    this.setAgGridAction(
                      'connectedDeviceId',
                      selectedDeviceInfo?.id,
                    );
                    break;

                  case 'delete':
                    this.setAgGridAction(
                      'unconnectedDeviceId',
                      selectedDeviceInfo?.id || `ERROR${deviceSerial}`,
                    );
                    break;
                  default:
                    break;
                }

                this.alertStore.setAlertOpen(
                  'success',
                  '장비 제어 명령에 성공했습니다.',
                );
              }
            });
          });
          this.setAgGridAction('selectedDeviceData', null);
          this.setAgGridAction('unconnectedGridPageForce', null);
          this.setAgGridAction('connectedGridPageForce', null);
        });
        break;

      default:
        console.error('Invalid control status');
        break;
    }
    // await this.initErrorSlavesList(serial);
  };
}

export default EdmuStore;
