import { makeAutoObservable, runInAction } from 'mobx';
import { getDashboardData, getDashboardLogData } from 'src/api/dashboard';

class DashBoardStore {
  alertStore;

  utilStore;

  deviceStatistics = {
    totalCount: null,
    activeCount: null,
    unConnectedCount: null,
    utilizationRate: null,
    badConnectionCount: null,
  };

  timelineSetting = {
    checkedAlertValue: ['alert', 'confirm', 'warning', 'notice'],
    selectedDate: {
      start: '',
      end: '',
    },
  };

  constructor({ alertStore, utilStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
    this.utilStore = utilStore;
  }

  initializeDashboard = () => {
    this.deviceStatistics = {
      totalCount: null,
      activeCount: null,
      unConnectedCount: null,
      utilizationRate: null,
      badConnectionCount: null,
    };
  };

  getDashboardData = async () => {
    try {
      const response = await getDashboardData();
      runInAction(() => {
        this.deviceStatistics = response;
      });
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  getLogTableData = async () => {
    try {
      const res = await getDashboardLogData(
        ['alert', 'confirm', 'warning', 'notice'],
        {
          start: this.timelineSetting.selectedDate.start,
          end: this.timelineSetting.selectedDate.end,
        },
        this.utilStore.getTimezone(),
      );

      let logTableRows = [];

      res.logData.map((data) => {
        return logTableRows.push({
          date: data.timestamp,
          deviceType: data.deviceData?.type || '데이터 없음',
          deviceName: data.deviceData?.nickname,
          deviceSerial: data.deviceId,
          type: data.alertType,
          logType: data.logType,
          eventCode: data.eventCode,
          content: data.message,
        });
      });

      return logTableRows;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  setTimelineDate = (selectedDate) => {
    let startDate = selectedDate[0];
    let endDate = selectedDate[1];

    runInAction(() => {
      this.timelineSetting = {
        ...this.timelineSetting,
        selectedDate: { start: startDate, end: endDate },
      };
    });
  };
}

export default DashBoardStore;
