import { ParameterInfo as ThreePhaseSolarParameterInfo } from './ThreePhaseSolar';
import { ParameterInfo as TemperatureControllerParameterInfo } from './TemperatureController';
import { ParameterInfo as RouterParameterInfo } from './Router';

import { FirebaseConfig as ThreePhaseSolarFirebaseConfig } from './ThreePhaseSolar';
import { FirebaseConfig as TemperatureControllerFirebaseConfig } from './TemperatureController';
import { FirebaseConfig as RouterFirebaseConfig } from './Router';

export const GetDataConfig = {
  default: {
    TW: {
      statDate: { type: 'todayStart' },
      endDate: { type: 'todayEnd' },
      parameter: 'TW',
      period: 'day',
      processType: 'apexChart',
      limit: 31,
      sortType: 'asc',
      includeNull: true,
      reverse: false,
    },
  },
  'three-phase-solar': {
    EFF: {
      statDate: { type: 'current' },
      endDate: { type: 'current' },
      parameter: 'EFF',
      period: 1,
      processType: 'apexChart',
      limit: 1,
      sortType: 'desc',
      includeNull: false,
      reverse: true,
    },
    DGE: {
      statDate: { type: 'start' },
      endDate: { type: 'end' },
      parameter: 'PGE',
      period: 'day',
      processType: 'apexChart',
      limit: 31,
      sortType: 'asc',
      includeNull: true,
      reverse: false,
    },
  },
  'temperature-controller': {
    sensors: [],
  },
  thermohygrometer: {
    sensors: [],
  },
  router: {
    control: [],
  },
  fs600r: {
    sensors: [],
  },
};

export const ParameterInfo = {
  'three-phase-solar': ThreePhaseSolarParameterInfo,
  'temperature-controller': TemperatureControllerParameterInfo,
  router: RouterParameterInfo,
};

export const FirebaseConfig = {
  'three-phase-solar': ThreePhaseSolarFirebaseConfig,
  'temperature-controller': TemperatureControllerFirebaseConfig,
  router: RouterFirebaseConfig,
  default: ['sensors'],
  thermohygrometer: ['sensors'],
  fs600r: ['sensors'],
};

export default ParameterInfo;

// 37 8565 616706
