import { Box, Card, Typography, Container, Button, Grid } from '@mui/material';
import PageHelmet from 'src/components/PageHelmet';
import { useTranslation } from 'react-i18next';
import Logo from 'src/components/Logo';
import { ReactComponent as ErrorLogo } from 'src/assets/images/ErrorOccured.svg';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { MainContent, LanguageSwitcherWrapper } from '../style';

const UnexpectedErrorPage = () => {
  const { t } = useTranslation();

  const handleMoveMainPage = () => {
    window.location.href = '/';
  };

  return (
    <>
      <PageHelmet message={'에러 발생'} />
      <LanguageSwitcherWrapper>
        <LanguageSwitcher />
      </LanguageSwitcherWrapper>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <ErrorLogo className="main_image network_error_img" />
            <Typography variant="h2" className="title">
              {t('예기치 못한 에러가 발생했습니다.')}
            </Typography>
            <Typography
              variant="h4"
              className="text_content"
              color="text.secondary"
              fontWeight="normal"
            >
              {t(
                '서비스에 불편을 드려 대단히 죄송합니다.\n잠시 후 다시 시도해 주시거나 같은 문제가 반복될 경우 관리자에게 문의해 주세요.',
              )}
            </Typography>
          </Box>
          <Container maxWidth="sm">
            <Card className="card_box">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    className="text_content"
                    onClick={handleMoveMainPage}
                    variant="outlined"
                    fullWidth
                  >
                    {t('메인 페이지')}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Container>
        <Logo />
      </MainContent>
    </>
  );
};

export default UnexpectedErrorPage;
