import { createContext, useEffect, useReducer } from 'react';
import { firebaseAuth } from 'src/utils/firebase';
import {
  signInWithEmailAndPassword as fireBaseSignInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithCustomToken,
} from 'firebase/auth';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useStore, resetStore } from 'src/mobx-store';
import { edmuSocket } from 'src/utils/socket.io';

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};
const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,

      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInCustomToken: () => Promise.resolve(),
  getToken: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = observer((props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { accountStore, deviceStore } = useStore();
  const { getLogEvenTypes } = deviceStore;
  const { getProfile } = accountStore;

  const waitForSocketConnection = (timeout = 35000) => {
    return new Promise((resolve) => {
      // 이미 연결
      if (edmuSocket.connected) {
        resolve();
        return;
      }

      // 연결될때까지 대기 (타임아웃 시간동안)
      const timer = setTimeout(() => {
        edmuSocket.off('connect', resolve);
        resolve();
      }, timeout);

      edmuSocket.once('connect', () => {
        if (edmuSocket.connected) {
          resolve();
          clearTimeout(timer);
        }
      });
    });
  };

  useEffect(() => {
    onAuthStateChanged(
      firebaseAuth,
      async (user) => {
        if (user) {
          localStorage.setItem('accessToken', user.accessToken);
          edmuSocket.connect();

          await waitForSocketConnection();
          await getProfile();
          // await getDeviceTypes();
          await getLogEvenTypes();

          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      },
      (error) => {
        console.error(error);
      },
    );
  }, []);

  const signInWithEmailAndPassword = (email, password) => {
    return fireBaseSignInWithEmailAndPassword(firebaseAuth, email, password);
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();

    return signInWithPopup(firebaseAuth, provider);
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return firebaseCreateUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password,
    );
  };

  const signInCustomToken = (token) => {
    return signInWithCustomToken(firebaseAuth, token);
  };

  const logout = async () => {
    await signOut(firebaseAuth);
    localStorage.removeItem('accessToken');
    resetStore();
  };

  const getToken = () => {
    return firebaseAuth.currentUser.getIdToken();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        signInCustomToken,
        logout,
        getToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
